/* eslint-disable indent */
import ExcelJS, { Worksheet } from 'exceljs';
import FileSaver from 'file-saver';
import { isValueValid } from 'utils/functions/isValueValid';
import { TFunction } from 'i18next';
import moment from 'moment';
import { isNumber } from 'utils/format/numbers';
import { formatModifiedDatetime } from 'utils/format/dates';
import {
  buildAssetTypeTextMappingNoTranslation,
  buildDataChannelTypeTextMapping,
} from 'utils/i18n/enum-to-text';
import { numbersToDataChannelTypes } from 'utils/functions/numbersToDataChannelTypes';
import { Column, ReportInfo } from './types';

const assetTypeTranslation = buildAssetTypeTextMappingNoTranslation();

export type ExcelData = {
  title: string;
  data: any[];
};

const addDocumentHeaders = (
  worksheet: ExcelJS.Worksheet,
  reportInfo?: ReportInfo,
  t?: TFunction,
  lastColChar?: string
) => {
  const rowIndex = 1;

  worksheet.addRow([reportInfo?.name || 'Report']);
  worksheet.mergeCells(`A${rowIndex}:${lastColChar}${rowIndex}`);
  worksheet.getCell(`A${rowIndex}`).alignment = {
    vertical: 'middle',
    horizontal: 'center',
  };
  worksheet.getCell(`A${rowIndex}`).font = {
    bold: true,
    size: 16,
  };
  worksheet.addRow([
    'Filter By:	',
    reportInfo?.filterByEnumValue || 'Asset',
    'Group By:',
    reportInfo?.groupByEnumValue || 'Asset',
    'Item Count:',
    reportInfo?.count || 0,
  ]);
  worksheet.addRow([
    'Filter Text:',
    reportInfo?.filterText || 'Empty',
    'Units:',
    reportInfo?.unitTypeEnumValue || 'default',
  ]);
  worksheet.addRow([
    'Show Types:',
    numbersToDataChannelTypes(reportInfo?.includedDataChannelTypes, t),
  ]);
  worksheet.addRow([
    'Show Inventory State Types:',
    reportInfo?.hasInventoryState
      ? reportInfo?.includedInventoryStates || 'None'
      : 'None',
  ]);
  worksheet.addRow(['Time Period:', reportInfo?.reportTimePeriodText || 'All']);
};
const AreRowCellsMerged = (worksheet: ExcelJS.Worksheet, index: number) => {
  return worksheet.getCell(`A${index}`).isMerged;
};

const AdjustColumnWidth = (worksheet: Worksheet) => {
  worksheet.columns.forEach((column) => {
    if (!column || !column.values || column?.values?.length === 1) return;

    const lengths = column.values.map((v, index) => {
      if (AreRowCellsMerged(worksheet, index) || !isValueValid(v?.toString())) {
        return 0;
      }
      return v?.toString().length!;
    });

    const maxLength = Math.max(...lengths.filter((v) => typeof v === 'number'));

    column.width = maxLength + 2;
  });
};

const AddTitleRow = (
  worksheet: ExcelJS.Worksheet,
  item: ExcelData,
  lastColChar?: string
) => {
  if (!item.title) return;
  worksheet.addRow([item.title]);
  const rowIndex = worksheet.lastRow?.number;
  if (rowIndex) {
    worksheet.mergeCells(`A${rowIndex}:${lastColChar}${rowIndex}`);
    worksheet.getCell(`A${rowIndex}`).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFD732' },
      bgColor: { argb: 'FFFFD732' },
    };
  }
};

const setHeaderRowStyles = (worksheet: ExcelJS.Worksheet) => {
  const headerRow = worksheet.getRow(7);
  headerRow.height = 20;
  headerRow.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFA0A0A0' },
      bgColor: { argb: 'FFA0A0A0' },
    };
    cell.font = {
      bold: true,
    };
    cell.alignment = {
      vertical: 'middle',
    };
  });
};

const addDataRows = (
  item: ExcelData | any[],
  worksheet: ExcelJS.Worksheet,
  dataChannelTypeTextMapping: any,
  ianaTimezoneId?: string | null
) => {
  const includeDataAge = worksheet.columns.some(
    (head) => head.key === 'dataAge'
  );

  const includeSiteInfo = worksheet.columns.some(
    (head) => head.key === 'siteInfo'
  );

  const includeAssetType = worksheet.columns.some(
    (head) => head.key === 'assetType'
  );

  const includeReadings = worksheet.columns.some(
    (head) => head.key === 'reading'
  );

  const includeDataChannelType = worksheet.columns.some(
    (head) => head.key === 'dataChannelType'
  );

  const includeDeliverable = worksheet.columns.some(
    (head) => head.key === 'deliverable'
  );

  const includeStreetAddress = worksheet.columns.some(
    (head) => head.key === 'streetAddress'
  );

  const includeIntegrationEnabled = worksheet.columns.some(
    (head) => head.key === 'ftpEnabled'
  );

  const incluseReadingTime = worksheet.columns.some(
    (head) => head.key === 'readingTime'
  );

  if (Array.isArray(item)) {
    worksheet.addRow(item);
  } else {
    let newItem = { data: item.data, title: item.title } as ExcelData;
    if (
      includeSiteInfo ||
      includeAssetType ||
      includeReadings ||
      includeDataAge ||
      includeDataChannelType ||
      includeDeliverable ||
      includeStreetAddress
    ) {
      if (includeSiteInfo) {
        newItem.data = [
          {
            ...newItem.data[0],
            // @ts-ignore
            siteInfo: [
              newItem?.data[0].address1,
              newItem?.data[0].address2,
              newItem?.data[0].address3,
              newItem?.data[0].city,
              newItem?.data[0].state,
            ]
              .filter((part) => part && part.trim().length > 0)
              .join(', '),
          },
        ];
      }

      if (incluseReadingTime) {
        const formattedTime = formatModifiedDatetime(
          newItem.data[0].readingTime,
          ianaTimezoneId
        );

        newItem.data = [
          {
            ...newItem.data[0],
            // @ts-ignore
            readingTime: newItem.data[0].readingTime ? `${formattedTime}` : '',
          },
        ];
      }

      if (includeStreetAddress) {
        newItem.data = [
          {
            ...newItem.data[0],
            // @ts-ignore
            streetAddress: [
              newItem?.data[0].address1,
              newItem?.data[0].address2,
              newItem?.data[0].address3,
            ]
              .filter((part) => part && part.trim().length > 0)
              .join(', '),
          },
        ];
      }

      if (includeAssetType) {
        newItem.data = [
          {
            ...newItem.data[0],
            // @ts-ignore
            assetType:
              typeof newItem?.data[0].assetType === 'number'
                ? assetTypeTranslation[
                    newItem?.data[0]
                      .assetType as keyof typeof assetTypeTranslation
                  ]
                : newItem?.data[0].assetType || '',
          },
        ];
      }

      if (includeReadings) {
        newItem.data = [
          {
            ...newItem.data[0],
            // @ts-ignore
            reading: newItem.data[0].readingValue
              ? `${newItem.data[0].readingValue?.toFixed(2)} ${
                  newItem.data[0].scaledUnits || ''
                }`
              : '',
          },
        ];
      }

      if (includeDataAge) {
        const start = moment(new Date());
        const end = moment(item.data[0].readingTime as any);

        newItem.data = [
          {
            ...newItem.data[0],
            // @ts-ignore
            dataAge: newItem.data[0].readingTime
              ? Math.round(moment.duration(start.diff(end)).asHours() / 24)
              : '',
          },
        ];
      }

      if (includeDataChannelType) {
        newItem.data = [
          {
            ...newItem.data[0],
            // @ts-ignore
            dataChannelType: newItem.data[0].dataChannelType
              ? dataChannelTypeTextMapping[newItem.data[0].dataChannelType]
              : '',
          },
        ];
      }

      if (includeDeliverable) {
        newItem.data = [
          {
            ...newItem.data[0],
            // @ts-ignore
            deliverable:
              isNumber(newItem.data[0].displayDeliverable) &&
              newItem.data[0].displayUnits
                ? `${newItem.data[0].displayDeliverable} ${newItem.data[0].displayUnits}`
                : '',
          },
        ];
      }

      if (includeIntegrationEnabled) {
        newItem.data = [
          {
            ...newItem.data[0],
            // @ts-ignore
            ftpEnabled: newItem.data[0].ftpEnabled ? 'Yes' : 'No',
          },
        ];
      }
    } else {
      newItem = item;
    }
    newItem.data.forEach((row) => {
      worksheet.addRow(row);
    });
  }
};

const getExcelLastColumnName = (index: number) => {
  let columnName = '';

  columnName = String.fromCharCode(64 + index) + columnName;

  return columnName;
};
export const excelExport = async (
  columns: Column[],
  data: ExcelData[],
  fileName?: string | null,
  reportInfo?: ReportInfo,
  t?: TFunction,
  ianaTimezoneId?: string | null
) => {
  const dataChannelTypeTextMapping = buildDataChannelTypeTextMapping(t!);
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('My Sheet');
  worksheet.views = [{ topLeftCell: 'A4', showRowColHeaders: false }];
  const lastColChar = getExcelLastColumnName(columns.length);
  addDocumentHeaders(worksheet, reportInfo, t, lastColChar);
  worksheet.addRow(columns.map((column) => column.header));
  worksheet.columns = columns.map((column) => {
    return {
      // header: column.header,
      key: column.key,
      width: column.width,
      func: column.func,
    };
  });
  setHeaderRowStyles(worksheet);

  data.forEach((item: ExcelData) => {
    AddTitleRow(worksheet, item, lastColChar);

    addDataRows(item, worksheet, dataChannelTypeTextMapping, ianaTimezoneId);
  });

  AdjustColumnWidth(worksheet);

  const buf = await workbook.xlsx.writeBuffer();

  const blb = new Blob([buf], { type: 'application/vnd.ms-excel' });

  FileSaver.saveAs(blb, fileName || 'default.xlsx');
};
