/* eslint-disable indent */
import {
  AssetSummaryTemplateField,
  DataChannelType,
  ReportType,
} from 'api/admin/api';
import {
  buildAssetTypeTextMappingNoTranslation,
  buildDataChannelTypeTextMapping,
} from 'utils/i18n/enum-to-text';
import { TFunction } from 'i18next';
import moment from 'moment';
import { formatModifiedDatetime } from 'utils/format/dates';

const assetTypeTranslation = buildAssetTypeTextMappingNoTranslation();

export type OrderInfo = {
  header: string;
  key: string;
  func?: (value?: any) => string;
};
export type CsvColumnSettings = {
  order?: OrderInfo[];
};
export const CsvReportColumns = (
  t: TFunction,
  reportType?: ReportType,
  domainAssetTemplates?: AssetSummaryTemplateField[],
  ianaTimezoneId?: string | null
): CsvColumnSettings => {
  if (!reportType) {
    return {};
  }
  const dataChannelTypeTextMapping = buildDataChannelTypeTextMapping(t);
  switch (reportType) {
    case ReportType.LowBatteryVoltage:
      return {
        order: [
          { header: 'Domain', key: 'domainName' },
          { header: 'Asset', key: 'assetTitle' },
          { header: 'Description', key: 'dataChannelDescription' },
          { header: 'Customer', key: 'customerName' },
          { header: 'RTU', key: 'deviceId' },
          { header: 'Channel', key: 'channelNumber' },
          { header: 'Carrier', key: 'carrierName' },
          { header: 'Product', key: 'productName' },
          { header: 'Last Data Received', key: 'lastReadingTimestamp' },
          { header: 'Last Battery Voltage', key: 'latestBatteryVolts' },
          { header: 'Battery Low', key: 'batteryLow' },
        ],
      };
    case ReportType.DataChannelsWithMissingReadings:
      return {
        order: [
          { header: 'Domain', key: 'domainName' },
          { header: 'Asset', key: 'assetTitle' },
          { header: 'Description', key: 'dataChannelDescription' },
          { header: 'Customer', key: 'customerName' },
          { header: 'Address', key: 'address1' },
          { header: 'City', key: 'city' },
          { header: 'State', key: 'state' },
          { header: 'Country', key: 'country' },
          { header: 'RTU', key: 'deviceId' },
          { header: 'Channel', key: 'channelNumber' },
          { header: 'Carrier', key: 'carrierName' },
          { header: 'Product', key: 'productName' },
          { header: 'Last Date Received', key: 'lastReadingTimestamp' },
          { header: 'Expected Date On', key: 'lastReadingExpectedTimestamp' },
          { header: 'Delinquent Period', key: 'delinquentPeriodAsText' },
        ],
      };
    case ReportType.NewAssetList:
      return {
        order: [
          { header: 'Domain', key: 'domainName' },
          { header: 'RTU', key: 'rtuDeviceId' },
          { header: 'Asset Description', key: 'assetTitle' },
          {
            header: 'Asset Type',
            key: 'assetType',
            func: (data?: any) =>
              typeof data?.assetType === 'number'
                ? assetTypeTranslation[
                    data?.assetType as keyof typeof assetTypeTranslation
                  ]
                : '',
          },
          { header: 'Data Channels', key: 'dataChannelCount' },
          { header: 'Channel', key: 'channelNumber' },
          { header: 'Item', key: 'dataChannelDescription' },
          { header: 'Reading Time', key: 'readingTime' },
          {
            header: 'Reading',
            key: 'reading',
            func: (data?: any) => {
              if (data?.readingValue) {
                return `${data?.readingValue?.toFixed(2)} ${
                  data?.displayUnits || data?.scaledUnitsAsText
                }`;
              }
              return '';
            },
          },

          { header: 'Product', key: 'productDescription' },

          { header: 'Truck', key: 'truck' },
          {
            header: 'Status',
            key: 'eventStatus',
            func: (data?: any) =>
              data?.eventStatus ? data?.eventStatus : 'Normal',
          },
          { header: 'Scheduled Refill', key: 'scheduledRefill' },
          { header: 'Alarm Level', key: 'alarmLevel' },
          {
            header: 'Deliverable',
            key: 'deliverable',
            func: (data?: any) =>
              data?.deliverable ? `${data?.deliverable?.toFixed(2)}` : '',
          },
          {
            header: 'Asset Type',
            key: 'assetType',
            func: (data?: any) =>
              typeof data?.assetType === 'number'
                ? assetTypeTranslation[
                    data?.assetType as keyof typeof assetTypeTranslation
                  ]
                : '',
          },
          { header: 'RTU', key: 'rtuDeviceId' },
          { header: 'First Reading', key: 'firstReadingDate' },
          { header: 'Date Created', key: 'dataChannelCreatedDate' },
        ],
      };
    case ReportType.AssetSummaryList: {
      const columnsReturn = domainAssetTemplates
        ? domainAssetTemplates
            .filter((item) => item.displayTranscend) // Filtra los que tienen displayTranscend en true
            .sort((a, b) => Number(a.order) - Number(b.order)) // Ordena por order numérico
            .map(
              ({ name, title }) =>
                ({
                  header: title,
                  key: name
                    ? name?.charAt(0).toLowerCase() + name.slice(1)
                    : '', // Convierte la primera letra en minúscula
                } as OrderInfo)
            )
        : [];

      const columnsFormat = columnsReturn.map((column) => {
        if (column.key === 'streetAddress') {
          return {
            header: 'Street Address',
            key: 'streetAddress',
            func: (data?: any) => {
              if (data?.address1) {
                return `${[data?.address1, data?.address2, data?.address3]
                  .filter((part) => part && part.trim().length > 0)
                  .join(', ')}`;
              }
              return '';
            },
          };
        }

        if (column.key === 'reading') {
          return {
            header: 'Reading Value',
            key: 'reading',
            func: (data?: any) => {
              if (data?.readingValue) {
                return `${data?.readingValue?.toFixed(2)} ${
                  data?.scaledUnits || ''
                }`;
              }
              return '';
            },
          };
        }

        if (column.key === 'readingTime') {
          return {
            header: 'Reading Time',
            key: 'readingTime',
            func: (data?: any) => {
              if (data?.readingTime) {
                const formattedTime = formatModifiedDatetime(
                  data?.readingTime,
                  ianaTimezoneId
                );
                return `${formattedTime}`;
              }
              return '';
            },
          };
        }

        if (column.key === 'rTUDeviceId') {
          return {
            header: 'RTU',
            key: 'rtuDeviceId',
          };
        }

        if (column.key === 'ftpId') {
          return {
            header: 'Integration ID',
            key: 'ftpId1',
          };
        }

        if (column.key === 'status') {
          return {
            header: 'Status',
            key: 'eventStatus',
          };
        }

        if (column.key === 'ftpEnabled') {
          return {
            header: 'Integration Enabled',
            key: 'ftpEnabled',
            func: (data?: any) => {
              if (data?.ftpEnabled) {
                return 'Yes';
              }
              return 'No';
            },
          };
        }

        if (column.key === 'dataAge') {
          return {
            header: 'Data Age',
            key: 'dataAge',
            func: (data?: any) => {
              const start = moment(new Date());
              const end = moment(data?.readingTime as any);
              if (data?.readingTime) {
                return `${Math.round(
                  moment.duration(start.diff(end)).asHours() / 24
                )}`;
              }
              return '';
            },
          };
        }

        if (column.key === 'dataChannelType') {
          return {
            header: 'DC Type',
            key: 'dataChannelType',
            func: (data?: any) => {
              if (data?.dataChannelType) {
                return `${
                  dataChannelTypeTextMapping[
                    data?.dataChannelType as DataChannelType
                  ]
                }`;
              }
              return '';
            },
          };
        }

        return column;
      });
      return {
        order: columnsFormat || [],
      };
    }
    case ReportType.AssetList:
      return {
        order: [
          { header: 'Asset Description', key: 'assetTitle' },
          { header: 'Description', key: 'assetDescription' },
          { header: 'Customer', key: 'customerName' },
          { header: 'RTU', key: 'deviceId' },
          {
            header: 'Site Info',
            key: 'siteInfo',
            func: (data?: any) => {
              const addressParts: string[] = [
                data?.address1,
                data?.address2,
                data?.address3,
                data?.city,
                data?.state,
              ];
              return addressParts
                .filter((part) => part && part.trim().length > 0)
                .join(', ');
            },
          },
          { header: 'Product', key: 'productName' },
          { header: 'Data Channels', key: 'dataChannelCount' },
        ],
      };
    case ReportType.HornerDOEReport:
      return {
        order: [
          { header: 'Asset Description', key: 'assetTitle' },
          { header: 'Location', key: 'location' },
          { header: 'RTU', key: 'rtuDescription' },
          { header: 'Start Date Time', key: 'fillStartTime' },
          { header: 'Start Hose Pressure (MPa)', key: 'startHosePressure' },
          { header: 'Start Tank Pressure (MPa)', key: 'startTankPressure' },
          {
            header: 'Start Tank Temperature (°C)',
            key: 'startTankTemperature',
          },
          { header: 'Company Name', key: 'companyName' },
          { header: 'Company User Id', key: 'userNo' },
          { header: 'Ambient Temperature (°C)', key: 'ambientTemperature' },
          {
            header: 'Fill Duration (Seconds)',
            key: 'duration',
            func: (value?: number) => value?.toFixed(2) || '-',
          },
          { header: 'Flow (Kgs)', key: 'flow' },
          { header: 'Average Fill Rate (Kg/min)', key: 'averageFillRate' },
          { header: 'Fuel Temperature (°C)', key: 'fuelTemperature' },
          { header: 'End Date Time', key: 'fillEndTime' },
          { header: 'End Hose Pressure (MPa)', key: 'endPressure' },
          { header: 'End Tank Pressure (MPa)', key: 'endTankPressure' },
          { header: 'End Tank Temperature (°C)', key: 'endTankTemperature' },
          { header: 'Product Code', key: 'product' },
          { header: 'Blend Percent (%)', key: 'blend' },
          { header: 'Shutdown Reason', key: 'shutdownReason' },
          { header: 'Comm Type', key: 'commType' },
          { header: 'Comm Loss Count', key: 'commLossCount' },
          { header: 'Non Comm Reason', key: 'nonCommReason' },
          { header: 'Vehicle Id', key: 'vechicleId' },
          { header: 'Vehicle Odometer (Km)', key: 'odometerReading' },
        ],
      };
    case ReportType.HornerDetailReport:
      return {
        order: [
          { header: 'Fill Count', key: 'chan1' },
          { header: 'Time', key: 'readingTime' },
          { header: 'Step', key: 'chan2' },
          { header: 'PI-101B Bank Pressure (MPa)', key: 'chan3' },
          { header: 'PI-101C Bank Pressure (MPa)', key: 'chan4' },
          {
            header: 'PI-101D Bank Pressure (MPa)',
            key: 'chan5',
          },
          { header: 'H35 Pressure (MPa)', key: 'chan6' },
          { header: 'H70 Pressure (MPa)', key: 'chan7' },
          { header: 'Ramp Pressure (MPa)', key: 'chan8' },
          { header: 'Vehicle Pressure (MPa)', key: 'chan9' },
          { header: 'Tube Selected', key: 'chan10' },
          { header: 'Ambient Temperature (°C)', key: 'chan11' },
          { header: 'Vehicle Temperature (°C)', key: 'chan12' },
          { header: 'Fuel Temperature (°C)', key: 'chan13' },
          { header: 'Fuel Average Temperature (°C)', key: 'chan14' },
          { header: 'Fuel Storage (kg)', key: 'chan15' },
          { header: 'Flow (kg)', key: 'chan16' },
          { header: 'Flow Rate (Kg/min)', key: 'chan17' },
          { header: 'Vehicle Volume (l)', key: 'chan18' },
          { header: 'PI101 1 (MPa)', key: 'chan21' },
          { header: 'PI101 2 (MPa)', key: 'chan22' },
          { header: 'PI101 3', key: 'chan23' },
          { header: 'PI101 4 (MPa)', key: 'chan24' },
          { header: 'I/P Output (Counts)', key: 'chan25' },
          { header: 'TI-601 Cooling Block Zone 1 (°C)', key: 'chan26' },
          { header: 'TI-602 Cooling Block Zone 2 (°C)', key: 'chan27' },
          { header: 'TI-603 Cooling Block Zone 3 (°C)', key: 'chan28' },
          { header: 'TI-122 Dispenser Inlet (°C)', key: 'chan29' },
          { header: 'Purge Status', key: 'chan30' },
        ],
      };
    default:
      return {};
  }
};
